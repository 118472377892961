import { ILanguage } from "./ILanguage";

export const languagePT: ILanguage = {
  COMMON: {
    ProjectName: "Inventário",
    Cancel: "Cancelar",
    Create: "Criar",
    Add: "Adicionar",
    Import: "Importar",
    Change: "Alterar",
    Suspend: "Suspender",
    Save: "Guardar",
    Edit: "Editar",
    Remove: "Remover",
    Delete: "Apagar",
    Deactivate: "Desativar",
    Activate: "Ativar",
    Logout: "Terminar Sessão",
    Set: "Definir",
    Reset: "Alterar",
    Optional: "Opcional",
    Registar: "Registar",
    PrepositionForDateTime: " a ",
    CreatedOn: "Registada a",
    All: "Todos",
    Clear: "Limpar",
    Deliver: "Entregar",
    OtherActions: "Outras ações",
    CloneAction: "Clonar",
    ViewAll: "Ver mais",
    Update: "Atualizar",
    Close: "Fechar",
    Back: "Voltar", 
    
    FORM: {
      SelectManyOptions: "Selecione uma ou mais opções",
      Search: "Pesquisar",
      SearchPlaceholder: "Pesquisar",
      InitialDate: "Data Inicial",
      InitialDatePlaceholder: "Data Inicial",
      InitialCreationDatePlaceholder: "Data Inicial de Criação",
      FinalDate: "Data Final",
      FinalDatePlaceholder: "Data Final",
      FinalCreationDatePlaceholder: "Data Fim de Criação",
      RequestDatePlaceholder: "Selecione uma data",
      DefaultPlaceholder: "Digite Aqui",
      SelectDefaultPlaceholder: "Digite para pesquisar",
      SelectStatusPlaceholder: "Selecione estados",
      Filters: "Filtros",
      CleanFilters: "Limpar Filtros",
      ClearSelection: "Limpar seleção",
      Sunday: "Do",
      Monday: "Se",
      Tuesday: "Te",
      Wednesday: "Qa",
      Thursday: "Qi",
      Friday: "Sx",
      Saturday: "Sa",


      VALIDATIONS: {
        CannotBeEmpty: "Este campo não pode estar vazio.",
        RequiredField: "Este campo é obrigatório.",
        GuidValidator: "Formato GUID inválido.",
        SelectOneOption: "Selecione uma opção.",
        BatchFormatError: "Deverá conter o seguinte formato: ####### ou #######/# ou #######/##",
        FillMandatoryFields: "Por favor preencha os campos obrigatórios!",
        InvalidLocationId: "O ID da localização é inválido.",
        PositiveNumber: "Insira um número inteiro",
        SelectDate: "Por favor selecione uma data",
        PositiveNumberOrDecimal: "Digite um número inteiro ou decimal.",
        OnlyPositiveNumberOrDecimal: "Digite um número inteiro positivo ou decimal.",
      }
    },

    ERRORPAGES: {
      SomethingWrong: "Ocorreu um erro",
      BackToHome: "Página Inicial",
      AccessDenied: "Acesso Negado",
      AccessDeniedText1: "Não tem permissão para ver esta página!",
      AccessDeniedText2: "Por favor, verifique as suas credenciais e tente novamente.",
      PageNotFound: "Página Não Encontrada",
      PageNotFoundText: "Não conseguimos encontrar a página que procura.",
      SessionExpired: "Sessão Expirada",
      SessionExpiredText: "A sessão expirou devido à sua inatividade",
      Login: "Login",
    },

    SYSTEMPOPUPS: {
      Error: "Erro!",
      ErrorText: "",
      Success: "Sucesso!",
      SuccessText: "",
      Warning: "Aviso!",
      WarningText: "",
    },

    POPUPS: {
      ImportOptions: "Opções de Importação",
      ExcelTemplate: "Template Excel",
      ExcelTemplateText: "Clique no botão abaixo para fazer o download de um ficheiro modelo de Excel. Este modelo contém a estrutura correta para a importação de dados.",
      Import: "Importar",
      ImportText: "Clique no botão abaixo para selecionar um ficheiro Excel e iniciar o processo de importação. Certifique-se que seleciona o ficheiro correto.",
      ImportSapStockText: "Clique no botão abaixo para selecionar um ficheiro Excel com o stock exportado de SAP, de modo a iniciar o processo de importação. Certifique-se que seleciona o ficheiro correto.",
      UpdateItemsText: "Através da exportação do ficheiro excel, serve esta importação para atualizar as colunas do estado manualmente e importadas em Bulk (estado do Item e ID do Documento SAP)"

    },

    TABLE: {
      NoResults: "Nenhum resultado encontrado",
      NoResultsText: "Não encontramos o que procura, por favor procure novamente",
      RowsPerPage: "Items por pág.",
      Of: "de",
    }
  },

  INVENTORY: {

    ROLES: {
      Admin: "Administrador",
      BaseAccess: "Acesso básico",
      Finances: "Finanças",
      TeamLeader: "Gestor de Equipa"
    },

    Inventory: "Inventário",
    InventoryPlural: "Inventários",
    InventoryData: "Dados do Inventário",
    Storage: "Armazém",
    StoragePlural: "Armazéns",
    StorageData: "Dados do Armazém",
    MaterialData: "Dados do Item de Inventário",
    Export: "Exportar",
    Import: "Importar",
    NewInventory: "Novo inventário",
    SearchPlaceholder: "Pesquisar",
    Name: "Nome",
    NamePlaceholder: "Nome do Inventário",
    Type: "Tipo",
    InitialDate: "Data Inicial",
    FinalDate: "Data Final",
    SummaryStorage: "Resumo por armazéns",
    Status: "Estado",
    ReopenInventory: "Reabrir Inventário",
    CloseInventory: "Fechar",
    CancelInventory: "Cancelar",
    PDASimulator: "Simulador Contagens",
    ExportAccesses: "Exportar Cartões de Acesso",
    Batch: "Lote",
    Description: "Descrição",
    Material: "Material",

    STATUS: {
      Unknown: "Desconhecido",
      Counting: "Em Contangens",
      Validated: "Validado",
      Closed: "Fechado",
      ConfirmedPlural: "Confirmados",
      OnGoing: "A decorrer",
      Registered: "Registado",
      Canceled: "Cancelado",
    },
    POPUP: {
      DocumentSapId: "ID do Documento SAP",
      DocumentSapIdPlaceholder: "Ex: 100000001",
      DocumentSapIdSuccessMessage: "ID do Documento SAP Editado",
      EditDocumentSapId: "Editar ID do Documento SAP",
      RemoveInventory: "Remover Inventário",
      RemoveInventoryConfirmText: "Tem a certeza que pretende remover '{0}'?",
      RemoveInventorySuccessMessage: "Inventário removido.",
      CloseInventoryConfirmMessage: "Tem a certeza que pretende fechar '{0}'?",
      ReopenInventoryConfirmMessage: "Tem a certeza que pretende reabrir '{0}'?",
      CancelInventory: "Cancelar Inventário",
      CancelInventoryConfirmMessage: "Tem a certeza que pretende cancelar '{0}'?",
      History: "Histórico",
      NoHistoryLines: "Ainda não há registos de histórico sobre este item de inventário.",
      InvalidItemsCount: "{0} items de inventário não foram adicionados devido a propriedades inválidas (p.e. material inexistente, quantidades inválidas)."
    },

    DEVIATION: {
      SetDeviation: "Definir Desvio",
      ViewDeviation: "Consultar Desvio",
      SetInventoryDeviation: "Definir Desvio do Inventário",
      EditInventoryDeviation: "Editar Desvio do Inventário",
      SetDeviationSuccessMessage: "Definição do Desvio guardada",
      INFOANDFORM: {
        Quantity: "Qtd",
        Monetary: "Valor",

        MonetaryDeviationIntervalErrorMessage: "Diferença máxima monetária tem de ser superior à diferença minima monetária",
        QtdDeviationIntervalErrorMessage: "Diferença de quantidade máxima tem de ser superior à diferença mínima.",

        PercentageOfMinimumQuantityDifference: "Percentagem Mínima da Diferença de Quantidade (%)",
        PercentageOfMinimumQuantityDifferencePlaceholder: "Ex: 10",
        PercentageOfMaximumQuantityDifference: "Percentagem Máxima da Diferença de Quantidade (%)",
        PercentageOfMaximumQuantityDifferencePlaceholder: "Ex: 15",
        MinimumMonetaryDifference: "Diferença Monetária Mínima (€)",
        MinimumMonetaryDifferencePlacholder: "Ex: 200",
        MaximunMonetaryDifference: "Diferença Monetária Máxima (€)",
        MaximunMonetaryDifferencePlaceholder: "Ex: 350"
      }
    },

    STORAGE: {
      StorageLocations: "Locais de Armazenamento",
      CreateItem: "Criar Item",
      InventoryItems: "Items de Inventário",
      AutoValidate: "Auto Validar",
      AutoClose: "Auto Fechar",
      Description: "Descrição",

      STATUS: {
        Closed: "Fechado",
        ClosedPlural: "Fechados",
        OnGoing: "A decorrer",
        Registered: "Registado"
      },
      INFOANDFORM: {
        ValidatedCumulative: "Validados (Cumulativo)",
        NotStarted: "Contagens não iniciadas",
        Started: "Contagens iniciadas",
        TotalClosed: "Fechados",
        TotalInCounting: "Em Contagem",
        TotalItems: "Total de Items",
        TotalReadyToBeValidated: "Prontos para Validar",
        TotalValidated: "Validados",
        SAPLoc: "Localização SAP",
        NoOfMaterials: "Nº. Materiais",
        Released: "Lançados",
        Blocked: "Bloqueados",
        Closed: "Fechados",
        Status: "Estado",
      }
    },

    ITEMCOUNTS: {
      ItemCountMovedSuccessMessage: "Contagem de Item de Inventário movida",
      SelectTarget: "Selecione o Item de Inventário alvo para mover a contagem",
      InventoryItemTarget: "Possiveis Itens de Inventário Alvo",
      ItemCount: "Contagem de Item de Inventário",
      MoveCount: "Mover Contagem",
      Move: "Mover",
      RegistedDate: "Data de Registo"
    },

    ITEM: {
      NewItems: "Novos Items",
      CreateInventoryItem: "Criar Item de Inventário",
      InventoryItemCounts: "Contagens de Item de Inventário",
      TotalItems: "Total de Items",
      CreateItem: "Criar Item",
      InventoryItem: "Item de Inventário",
      InventoryItemsPlural: "Itens de Inventário",
      Item: "Item",
      ImportItems: "Importar Itens",
      UpdateItems: "Atualizar Itens",
      ImportLoading: "Os items importados estão a ser carregados. Isto pode levar algum tempo.",
      ValidateCounts: "Validar Contagens",
      ValidateCountsWarningText: "Está prestes a validar um Item que apresenta desvio, por favor justifique no campo abaixo.",
      ValidateCountsZeroQtyWarningText: "Está prestes a validar um Item onde a quantidade contada é zero (0), por favor justifique no campo abaixo.",
      AddComment: "Adicionar Comentário",
      ReopenCounts: "Reabrir Contagens",
      CloseItem: "Fechar Item",
      Remove: "Remover",
      Reopen: "Reabrir",
      Validate: "Validar",
      Close: "Fechar",

      TYPESAP: {
        StockBlocked: "Stock Bloqueado",
        StockFree: "Stock Livre",
        StockQuality: "Stock Qualidade",
      },

      MESSAGES: {
        InexistingStorageBin: "Compartimento inexistente",
        InexistingStorage: "Não foi encontrado um armazém com este código",
        InexistingMaterial: "Não foi encontrado um material com este código.",
        CloseItemSuccessMessage: "Item de Inventário fechado",
        CloseItemConfirmText: "Tem a certeza que pretende fechar este Item de Inventário?",
        ValidateItemSuccessMessage: "Item de Inventário validado",
        ValidateItemConfirmText: "Tem a certeza que pretende validar este Item de Inventário?",
        AutoValidateItemsConfirmText: "Está prestes a auto validar os Items deste armazém? Pretende continuar?",
        AutoValidateItemsSuccessMessage: "Itens Validados",
        ReopenItemSuccessMessage: "Item de Inventário reaberto",
        ReopenItemConfirmText: "Tem a certeza que pretende reabrir este Item de Inventário?",
        RemoveItemCountConfirmText: "Tem a certeza que pretende remove esta contagem?",
        RemoveItemCountSuccessMessage: "Contagem Removida",
        AddCommentCountSuccessMessage: "Comentário adicionado.",
        ImportItemsSuccess: "Items importados com sucesso!",
        WarningMoveItems: "Atenção: não é possível mover contagens para itens que se encontrem Validados ou Fechados."
      },

      STATUS: {
        Counting: "Em Contagem",
        Unknown: "Desconhecido",
        Validated: "Validado",
        ValidatedPlural: "Validados",
        Closed: "Fechado",
        ClosedPlural: "Fechados",
        OnGoing: "Contagem a decorrer",
        Valid: "Contagem validada",
        ValidPlural: "Contagens validadas",
        Problematic: "Problemáticos",
        ReadyToBeValidated: "Pronto para validação",
        Registered: "Registado"
      },

      INFOANDFORM: {
        Attributes: "Atributos",
        MonetaryValue: "Valor (€)",
        SapValue: "Valor SAP (€)",
        Problematic: "Problemático",
        MonetaryDeviationPercentage: "Desvio Monetário (%)",
        QuantityDeviationPercentage: "Desvio Quantidade (%)",
        MonetaryDeviation: "Desvio Monetário",
        QuantityDeviation: "Desvio de Quantidade",
        TypeSAP: "Tipo SAP",
        Counts: "Contagens",
        Rack: "Estante",
        Shelf: "Prateleira",
        Operator: "Operador",
        Storage: "Armazém",
        StoragePlaceholder: "Digite o armazém",
        Description: "Descrição",
        Material: "Material",
        MaterialNumber: "N.º do Material",
        MaterialNumberPlaceholder: "Digite o n.º do material",
        MaterialDescriptionPlaceholder: "Digite a descrição do material",
        MaterialDescription: "Descrição do Material",
        SapInventoryDocId: "ID do Documento de Inventário SAP",
        SapInventoryDocIdPlaceholder: "Digite o ID do Doc. de Inventário SAP",
        SAPQuantityExtended: "Quantidade SAP",
        SAPQuantityPlaceholder: "Digite a quantidade SAP",
        Batch: "Lote",
        BatchPlaceholder: "Digite o lote",
        StorageBin: "Compartimento do Armazém",
        StorageBinPlural: "Compartimentos do Armazém",
        SeeComments: "Consultar comentários",
        Bin: "Compartimento",
        BinPlural: "Compartimentos",
        BinPlaceholder: "Digite o compartimento",
        Explanation: "Justificação",
        Comments: "Comentários",
        CommentsPlaceholder: "Digite os seus comentários",
        LastComment: "Último Comentário",
        ExplantionComments: "Justificação/Comentários",
        Unit: "Unidade",
        UnitPlaceholder: "Digite a unidade",
        Type: "Tipo",
        Quantity: "Quantidade",
        QuantityPlaceholder: "Digite a quantidade",
        CountedQuantity: "Qtd. Contada",
        SAPQuantity: "Qtd. SAP",
        Deviation: "Desvio",
        ItemCounts: "Contagens",
        Status: "Estado",
        ItemCountsStatus: "Contagens/Estado",
        Justification: "Justificação",
        JustificationPlaceholder: "Digite uma justificação para esta decisão",
        SingularComment: "Comentário",
        SingularCommentPlaceholder: "Digite o seu comentário",
        SapTypeId: "ID Tipo",
        SpecialSap: "Special SAP",
        UnitPriceMaterialShort: "P.U. Material (€)"
      },

      POPUP: {
        EditQuantity: "Editar Quantidade",
        EditQuantitySuccessMessage: "Quantidade Editada",
      }
    }
  },

  GLOBALADMIN: {
    GlobalAdmin: "Administração global",

    STORAGEMANAGERS: {
      StorageManagerStoragesEdited: "Armazéns do Gestor atualizados.",
      EditStorages: "Editar Armazéns",
      EditManagersStorage: "Editar Armazéns do Gestor",
      ManagerData: "Dados do Gestor",
      StorageLocationsOf: "Armazéns de {0}",
      DeleteManagerConfirmText: "Tem a certeza que pretende remover este Gestor de Armazém '{0}'?",
      DeleteManagerSuccess: "Gestor de Armazém removido",
      AddStorageManager: "Adicionar Gestor de Armazém",
      SelectOneStorageWarningMessage: "Selecione pelo menos um Armazém",
      StorageManagers: "Gestores de Armazéns",
      ImportLoading: "Os gestores importados estão a ser carregados. Isto pode levar algum tempo.",
      ImportStorageManagersSuccess: "Gestores importados com sucesso!",
      ImportStorageManagerSingleSuccess: "Gestor adicionado!",

      Messages:{
        RemoveStorageConfirmText: "Tem a certeza que pretende remover o armazém '{0}' para o Gestor '{1}'?"
      } 

    },
    STORAGES: {
      Storages: "Armazéns",
      ImportStorageBins: "Importar Compartimentos",
      ImportStorages: "Importar Armazéns",
      AddStorageLocation: "Adicionar Armazém",
      EditStorageLocation: "Editar Armazém",
      ImportStoragesLoading: "Os armazéns importados estão a ser carregados. Isto pode levar algum tempo.",
      ImportStorageBinsLoading: "Os compartimentos importados estão a ser carregados. Isto pode levar algum tempo.",

      BINS: {
        AddBin: "Adicionar Compartimento ao Armazém",
        AddBinShort: "Adicionar Compartimento",
        BinPlaceholder: "Digite o nome do Compartimento",

        Messages: {
          AddBinSuccess: "Compartimento adicionado",
        }
      },

      Messages: {
        EditStorageSuccess: "Armazém Editado.",
        AddStorageSuccess: "Armazém Adicionado.",
        ImportStoragesSuccess: "Armazéns importados com sucesso!",
        ImportBinsSuccessfully: "Compartimentos importados com sucesso!",
        DeleteStorageConfirmText: "Tem a certeza que pretende remover este Armazém '{0}' - '{1}'?",
        DeleteStorageSuccess: "Armazém Removido.",
        EnableStorageSuccess: "Armazém Ativado.",
        DisableStorageSuccess: "Armazém Desativado.",

        RemoveBinConfirmText: "Tem a certeza que pretende remover o compartimento '{0} do armazém '{1} - {2}'?",
        RemoveBinSuccess: "Compartimento Removido"
      },

      Info: {
        Name: "Nome",
        NamePlaceholder: "Digite o nome do Armazém",
        Code: "Localização SAP",

      },

      POPUP: {
        Activate: "Ativar",
        ActivateConfirmText: "Tem a certeza que quer ativar o armazém?",
        ActivateStorageError: "Erro ao ativar armazém!",
        ActivateStorageSuccess: "Armazém ativado!",
        CreateStorageSuccess: "Armazém criado!",
        EditStorage: "Editar Armazém",
        EditStorageError: "Erro ao editar Armazém!",
        EditStorageSuccess: "Armazém editado!",
        DeactivateStorageError: "Erro ao desativar armazém!",
        Deactivate: "Desativar",
        DeactivateConfirmText: "Tem a certeza que quer desativar o armazém?",
        DeactivateStorageSuccess: "Armazém desativado!"
      },
    },

    OPERATORS: {
      PdaOperatorInfo: "Informação do operador de PDA",
      PdaOperators: "Operadores PDA",
      ImportLoading: "Os operadores importados estão a ser carregados. Isto pode levar algum tempo.",
      ImportPdaOperatorsSuccess: "Operadores importados com sucesso!",
      AssociateStorageManager: "Associar Gestor de Armazém",

      INFOANDFORM: {
        OperatorName: "Nome do Operador",
        StorageManagerOperator: "Gestor de Armazém do Operador PDA",
        Name: "Nome",
        Pass: "Palavra-passe",
        LastPasswordReset: "Última alteração da palavra-passe",
        AddPdaOperator: "Adicionar Operador de PDA",
        StorageManager: "Gestor de Armazém",
        RemovePdaOperatorTooltip: "Remover Operador PDA",
        RemoveManagerAssociationTooltip: "Remover Gestor de Equipa do Operador PDA",
        ResetPdaOperatorPasswordTooltip: "Resetar password do Operador PDA",
        AssociateStorageManagerTooltip: "Associar Gestor de Armazém a Operador de PDA",
        SelectOneManager: "Selecione um Gestor"
      },

      NOTIFICATIONS: {
        DeleteOperatorConfirmText: "Tem a certeza que pretende remover o operador '{0}'?",
        DeleteOperatorSuccessMessage: "Operador Removido",
        ResetPasswordConfirmText: "Tem a certeza que pretende alterar a palavra-passe de '{0}'?",
        ResetPasswordSuccessMessage: "Palavra-passe alterada",
        PdaOperatorAddedSuccessMessage: "Novo Operador de PDA adicionado.",
        RemoveManagerAssociationSuccess: "Gestor de '{0}' removido com sucesso.",
        RemoveManagerAssociationConfirmText: "Tem a certeza que pretende remover o Gestor de {0}?",
        ManagerAssociatedSuccess: "Gestor de Armazém associado."
      }

    },

    MATERIALS: {
      Materials: "Materiais",
      ImportLoading: "Os materiais importados estão a ser carregados. Isto pode levar algum tempo.",
      ImportMaterialsSuccess: "Materiais importados com sucesso!",

      INFO: {
        Plant: "Localização",
        SAPNumber: "Código SAP",
        Description: "Descrição",
        Units: "Unidades",
        Value: "Valor Unitário",
      },
      POPUP: {
        CreateMaterial: "Criar Material",
        CreateMaterialSuccess: "Material criado!",
        EditMaterial: "Editar Material",
        EditMaterialSuccess: "Material editado!",
        DeleteMaterialConfirmText: "Tem a certeza que quer apagar o material?",
        DeactivateMaterialConfirmText: "Tem a certeza que quer desativar o material?",
        Deactivate: "Desativar",
        ActivateMaterialConfirmText: "Tem a certeza que quer ativar o material?",
        DisableMaterialSuccess: "Material desativado!",
        ActivateMaterialSuccess: "Material ativado",
      }
    },


    LOCATIONS: {
      Locations: "Localizações",
      CreateLocation: "Criar Localização",
      AccessGroups: "Grupos de Acesso",

      INFO: {
        Name: "Nome",
        NumGroups: "Nº Grupos",
        Type: "Tipo",
      },

      POPUP: {
        Activate: "Ativar",
        ActivateConfirmText: "Tem a certeza que quer ativar a localização?",
        ActivateLocationError: "Erro ao ativar localização!",
        ActivateLocationSuccess: "Localização ativada!",
        CreateLocationSuccess: "Localização criada!",
        EditLocation: "Editar Localização",
        EditLocationError: "Erro ao editar localização!",
        EditLocationSuccess: "Localização editada!",
        DeactivateLocationError: "Erro ao desativar localização!",
        Deactivate: "Desativar",
        DeactivateConfirmText: "Tem a certeza que quer desativar a localização?",
        DeactivateLocationSuccess: "Localização desativada!"
      },

      ACCESSGROUPS: {
        Type: "Tipo",
        Name: "Nome",
      },
    },

    FACILITIES: {
      CreateFacility: "Criar Unidade",
      Facilities: "Unidades",
      INFOANDFORM: {
        Name: "Nome",
      },
      POPUP: {
        ActivateFacilityConfirmText: "Tem a certeza que quer ativar a unidade?",
        ActivateFacilitySuccess: "Unidade ativada!",
        ActivateFacilityError: "Erro ao ativar a unidade!",
        CreateFacilitySuccess: "Unidade criada!",
        DeactivateFacilityError: "Erro ao desativar unidade!",
        DeactivateFacilityConfirmText: "Tem a certeza que quer desativar a unidade?",
        DeactivateFacilitySuccess: "Unidade desativada!",
        Deactivate: "Desativar",
        EditFacilityError: "Erro ao editar a unidade!",
        EditFacility: "Editar Unidade",
        EditFacilitySuccess: "Unidade editada!",
        LoadingFacilityError: "Erro ao carregar a informação da unidade!"
      }
    },

    ACCESSGROUPS:
    {
      EditedGroupSuccess: "Grupos de acesso editados!",
      AccessGroups: "Grupos de Acesso",
      SubmitError: "Por favor, verifique os erros no formulário!",
      TYPES: {
        ACCESS: "Consulta",
        REGISTER_SAMPLES: "Regista Amostra",
        ADMIN: "Administrador",
        ANALISTS: "Analista",
        ANALISYS_REQUEST_GLOBAL: "Consulta Pedidos",
        AR: "AR",
        DF: "DF",
        DRYER: "Dryer",
        PP: "PP",
        WF: "WF"
      }
    },
  },
};
