import * as qs from "qs";

import { AllInventorySummary, InventorySummary } from "../models/domain/inventory/InventorySummary";

import { APIUserToDomainMapper } from "../../../models/api/users/APIUserToDomainMapper";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { DateTime } from "luxon";
import { DeviationDefinition } from "../models/domain/inventory/DeviationDefinition";
import { DeviationDefinitionDTO } from "../models/dtos/InventoryDTOs/DeviationDefinitionDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { InventoriesResponseDTO } from "../models/dtos/InventoryDTOs/InventoriesResponseDTO";
import { InventoryCreateDTO } from "../models/dtos/InventoryDTOs/InventoryCreateDTO";
import { InventoryDetails } from "../models/domain/inventory/InventoryDetails";
import { InventoryDetailsDTO } from "../models/dtos/InventoryDTOs/InventoryDetailsDTO";
import { InventoryDetailsToDomainMapper } from "../models/mappers/InventoryDetailsToDomainMapper";
import { InventoryStateUpdateDTO } from "../models/dtos/InventoryDTOs/InventoryStateUpdateDTO";
import { InventoryStats } from "../models/domain/inventory/InventoryStats";
import { InventoryStatsDTO } from "../models/dtos/InventoryDTOs/InventoryStatsDto";
import { InventoryUpdateDTO } from "../models/dtos/InventoryDTOs/InventoryUpdateDTO";
import { QueryOptions } from "../../../models/domain/materials/QueryOptions";
import { SetDeviationRequestDTO } from "../models/dtos/InventoryDTOs/SetDeviationRequestDTO";
import { SignURLRequestDTO } from "../models/dtos/SignURLRequestDTO";
import { SignURLResponseDTO } from "../models/dtos/SignURLResponseDTO";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class InventoriesService {

    getInventoryByID(locationId: string, inventoryID: string): Promise<InventoryDetails | null> {
        return HttpClient.sessionRequest<InventoryDetailsDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryID}`)
        })
            .then((r) => {

                if (!r.data) return null;

                var response: InventoryDetails = {
                    id: r.data.id,
                    name: r.data.name,
                    startDate: r.data.startDate,
                    endDate: r.data.endDate,
                    status: r.data.state,
                    modifiedBy: r.data.modifiedBy ? APIUserToDomainMapper(r.data.modifiedBy) : null,
                    modifiedDate: r.data.modifiedDate,
                    createdDate: r.data.createdDate,
                    createdBy: r.data.createdBy ? APIUserToDomainMapper(r.data.createdBy) : null
                };

                return response;
            })
            .catch((error) => { throw error; });
    }


    getInventoryStats(locationId: string, inventoryID: string): Promise<InventoryStats> {
        return HttpClient.sessionRequest<InventoryStatsDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryID}/Stats`)
        })
            .then((r) => {
                var response: InventoryStats = {
                    totalItens: r.data.totalItens,
                    totalInCounting: r.data.totalInCounting,
                    totalReadyToBeValidated: r.data.totalReadyToBeValidated,
                    totalValidated: r.data.totalValidated,
                    totalClosed: r.data.totalClosed,
                };

                return response;
            })
            .catch((error) => { throw error; });
    }


    getInventories(locationId: string, currentPage: number, itemsPerPage: number): Promise<AllInventorySummary> {

        var query: QueryOptions = {
            pageLength: itemsPerPage,
            page: currentPage
        };

        return HttpClient.sessionRequest<InventoriesResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Inventories`),
            params: query,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {

                var mappedInventories: InventorySummary[] = response.data.inventories.map(ob => ({
                    id: ob.id,
                    name: ob.name,
                    initialDate: ob.startDate,
                    finalDate: ob.endDate,
                    status: ob.status,
                    summary: {
                        onGoing: null,
                        confirmed: null,
                        finished: null
                    }
                }));

                return {
                    inventories: mappedInventories,
                    totalItems: response.data.totalItems
                };
            })
            .catch((error) => { throw error; });
    }

    addInventory(locationId: string, name: string, startDate: DateTime | undefined, endDate: DateTime | undefined): Promise<InventoryDetails> {

        var requestDto: InventoryCreateDTO = {
            name: name,
            startDate: startDate ? startDate?.toUTC().toISO() : null,
            endDate: endDate ? endDate?.toUTC().toISO() : null
        };

        return HttpClient.sessionRequest<InventoryDetailsDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/Inventories`),
            data: requestDto,
        })
            .then((r) => {

                var response: InventoryDetails = {
                    id: r.data.id,
                    name: r.data.name,
                    startDate: r.data.startDate,
                    endDate: r.data.endDate,
                    status: r.data.state,
                    modifiedBy: r.data.modifiedBy ? APIUserToDomainMapper(r.data.modifiedBy) : null,
                    modifiedDate: r.data.modifiedDate,
                    createdDate: r.data.createdDate,
                    createdBy: r.data.createdBy ? APIUserToDomainMapper(r.data.createdBy) : null
                };

                return response;
            })
            .catch((error) => {
                throw error;
            });
    }

    deleteInventory(locationId: string, id: number): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${id}`),
        })
            .then((_) => { })
            .catch((error) => { throw error; });
    }

    updateInventory(locationId: string, inventoryId: string, requestDto: InventoryUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryId}`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    updateInventoryState(locationId: string, inventoryId: string, requestDto: InventoryStateUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryId}/State`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }


    downloadUrlForInventoryExportReport(locationId: string, inventoryId: string): Promise<string> {

        return Promise.resolve(Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryId}/AsXLSX`));
    }



    downloadUrlForInventoryAccesses(locationId: string, inventoryId: string): Promise<string> {

        var requestDTO: SignURLRequestDTO = {
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryId}/Accesses`)
        }

        return HttpClient.sessionRequest<SignURLResponseDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/SignURL`),
            data: requestDTO
        })
            .then((res) => {
                return res.data.url;
            })
            .catch((error) => {
                throw error;
            });
    }



    setInventoryDeviationDefinition(locationID: string, inventoryID: string, requestDto: SetDeviationRequestDTO): Promise<void> {

        return HttpClient.sessionRequest<InventoryDetailsDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationID}/Inventories/${inventoryID}/DeviationDefinition`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    getInventoryDeviationDefinition(locationID: string, inventoryID: string): Promise<DeviationDefinition | null> {

        return HttpClient.sessionRequest<DeviationDefinitionDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationID}/Inventories/${inventoryID}/DeviationDefinition`),
        })
            .then((res) => {

                if (!res.data)
                    return null;

                var mappedDefinition: DeviationDefinition = {
                    id: res.data.id,
                    monetaryDifferenceMax: "" + res.data.monetaryDifferenceMax,
                    monetaryDifferenceMin: "" + res.data.monetaryDifferenceMin,
                    quantityDifferencePercentageMax: "" + res.data.quantityDifferencePercentageMax,
                    quantityDifferencePercentageMin: "" + res.data.quantityDifferencePercentageMin
                };

                return mappedDefinition;

            })
            .catch((error) => {
                throw error;
            });
    }


    editInventoryItemsFromXLSX(locationId: string, inventoryId: string, file: File): Promise<void> {

        var formData = new FormData();
        formData.append("file", file)

        return HttpClient.sessionRequest({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryId}/FromXLSX`),
            data: formData
        })
            .then((_) => {

            })
            .catch((error) => {
                throw error;
            });
    }
}
