import { Optional, useFormControl } from "../../../../../lib/components/form/Form";
import { regexIntegerOrDecimals, validateRegexOrEmpty } from "../../../../../common/validators/ValidateRegexOrEmpty";
import { useCallback, useEffect } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { InventoryItemCountsService } from "../../../services/InventoryItemCountsService";
import { InventoryItemsService } from "../../../services/InventoryItemsService";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../../lib/utils/TrimString";
import { useServiceCallPro } from "../../../../../services/UseServiceCall";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { formatNumber } from "../../../../../common/helpers/RoundNumber";

var svc = new InventoryItemsService();
var svcCounts = new InventoryItemCountsService();

function replaceCommaByPoint(value: string | undefined) {
    if (!value) return null;
    if (value?.includes(','))
        return trimString(value.replace(',', '.'));
    return trimString(value);
}

export interface IEditInventoryDocItemQuantityPopupProps {
    locationID: string;
    inventoryID: string;
    inventoryItemID: string;
    inventoryItemCountId: string;
    itemUnit?: string;
    onCompletedOperations: () => void;
}
var itemQuantityUnit = "";

export function EditInventoryDocItemQuantityPopup(props: IEditInventoryDocItemQuantityPopupProps) {
    const openPopup = usePopup(); 
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();

    const getInventoryItemCountCall = useServiceCallPro(svcCounts.getInventoryItemCounts);
    const putDocumentQuantity = useServiceCallPro(svcCounts.updateInventoryItemCount);

    const documentItemQuantityFormControl = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexIntegerOrDecimals, false, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });



    useEffect(() => {
        if (!props.locationID || !props.inventoryID || !props.inventoryItemID) return;

        getInventoryItemCountCall.invoke(props.locationID, props.inventoryID, props.inventoryItemID)
            .then((r) => {

                //TODO: TEMP for DEMO
                var filtered = r.items.find((a) => "" + a.id === props.inventoryItemCountId);
                if(filtered?.quantity){
                    documentItemQuantityFormControl.setValue("" + formatNumber(filtered?.quantity) ?? undefined)
                }
             
              
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
             });
    }, []);



    const handleEditBtnClicked = useCallback(() => {

        if (!documentItemQuantityFormControl.validate() || !props.inventoryItemID) return;

        putDocumentQuantity.invoke(props.locationID, props.inventoryID, `${props.inventoryItemID}`, `${props.inventoryItemCountId}`, { quantity: parseFloat(replaceCommaByPoint(documentItemQuantityFormControl.value) || "0") })
            .then(() => {
                props.onCompletedOperations();
                closePopup();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("INVENTORY.ITEM.POPUP.EditQuantitySuccessMessage")}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
             });
    }, [documentItemQuantityFormControl.validate, putDocumentQuantity, props.onCompletedOperations,
        createNotification, closePopup, props.inventoryItemID,
    props.locationID, props.inventoryID, documentItemQuantityFormControl.value]);




    return (
        <PopupContainer className="popup-edit-sapDocumentQuantity">
            {putDocumentQuantity.isLoading ? <FullScreenLoader /> : null}
            <PopupHeader
                title={translate("INVENTORY.ITEM.POPUP.EditQuantity")}
            />
            <PopupContent isLoading={getInventoryItemCountCall.isLoading}>
                <FormContainer>
                    <FormFieldTextInput
                        formControl={documentItemQuantityFormControl}
                        label={props.itemUnit ?
                            translate("INVENTORY.ITEM.INFOANDFORM.Quantity") + " (" + props.itemUnit + ")" :
                            translate("INVENTORY.ITEM.INFOANDFORM.Quantity")}
                        placeholder={translate("INVENTORY.ITEM.INFOANDFORM.QuantityPlaceholder")}
                    />
                </FormContainer>
                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Edit"),
                            type: "primary",
                            onClick: handleEditBtnClicked
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
