import * as qs from "qs";

import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { InventoryItemCountComment } from "../models/domain/inventory-item-counts/InventoryItemCountComment";
import { InventoryItemCountCommentCreateDTO } from "../models/dtos/InventoryItemCountDTOs/InventoryItemCountCommentCreateDTO";
import { InventoryItemCountCommentsResponseDTO } from "../models/dtos/InventoryItemCountDTOs/InventoryItemCountCommentsResponseDTO";
import { InventoryItemCountCreateDTO } from "../models/dtos/InventoryItemCountDTOs/InventoryItemCountCreateDTO";
import { InventoryItemCountMoveRequestDTO } from "../models/dtos/InventoryItemCountDTOs/InventoryItemCountMoveRequestDTO";
import { InventoryItemCountSummary } from "../models/domain/inventory-item-counts/InventoryItemCountSummary";
import { InventoryItemCountSummaryDTO } from "../models/dtos/InventoryItemCountDTOs/InventoryItemCountSummaryDTO";
import { InventoryItemCountUpdateDTO } from "../models/dtos/InventoryItemCountDTOs/InventoryItemCountUpdateDTO";
import { InventoryItemCountsResponse } from "../models/domain/inventory-item-counts/InventoryItemCountsResponse";
import { InventoryItemCountsResponseDTO } from "../models/dtos/InventoryItemCountDTOs/InventoryItemCountsResponseDTO";
import { QueryOptions } from "../../../models/domain/materials/QueryOptions";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class InventoryItemCountsService {

    getInventoryItemCounts(locationId: string, inventoryID: string, itemID: string, options?: QueryOptions): Promise<InventoryItemCountsResponse> {
        return HttpClient.sessionRequest<InventoryItemCountsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryID}/Items/${itemID}/Counts`),
            params: options,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {

                var mappedItemCounts: InventoryItemCountSummary[] = response.data.counts.map(ob => ({
                    id: ob.id,
                    storage: ob.storage,
                    rackShelf: ob.rackShelf,
                    quantity: ob.quantity,
                    createdDate: ob.createdDate,
                    comments: ob.comments.map(({ id, createdBy, createdDate, comment }) => ({
                        id, createdBy, createdDate, comment
                    })),
                    operator: ob.operator
                }));

                return {
                    items: mappedItemCounts,
                    totalItems: response.data.totalItems
                };
            })
            .catch((error) => { throw error; });
    }


    getInventoryItemCountByID(locationId: string, inventoryID: string, itemID: string, itemCountID: string): Promise<InventoryItemCountSummary> {
        return HttpClient.sessionRequest<InventoryItemCountSummaryDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryID}/Items/${itemID}/Counts/${itemCountID}`),
        })
            .then((r) => {

                var mappedItemCount: InventoryItemCountSummary = {
                    id: r.data.id,
                    storage: r.data.storage,
                    rackShelf: r.data.rackShelf,
                    quantity: r.data.quantity,
                    createdDate: r.data.createdDate
                };

                return mappedItemCount;
            })
            .catch((error) => { throw error; });
    }


    addInventoryItemCount(locationId: string, inventoryID: string, inventoryItemId: string, requestDto: InventoryItemCountCreateDTO): Promise<void> {

        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryID}/Items${inventoryItemId}/Counts`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    deleteInventoryItemCount(locationId: string, inventoryId: string, inventoryItemId: string, inventoryItemCountId: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryId}/Items/${inventoryItemId}/Counts/${inventoryItemCountId}`),
        })
            .then((_) => { })
            .catch((error) => { throw error; });
    }

    updateInventoryItemCount(locationId: string, inventoryId: string, inventoryItemId: string, inventoryItemCountId: string, requestDto: InventoryItemCountUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryId}/Items/${inventoryItemId}/Counts/${inventoryItemCountId}`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    addInventoryItemCountComment(locationId: string, inventoryId: string, inventoryItemId: string, inventoryItemCountId: string, requestDto: InventoryItemCountCommentCreateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryId}/Items/${inventoryItemId}/Counts/${inventoryItemCountId}/Comments`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }


    getInventoryItemCountComments(locationId: string, inventoryId: string, inventoryItemId: string, countId: string): Promise<InventoryItemCountComment[]> {
        return HttpClient.sessionRequest<InventoryItemCountCommentsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryId}/Items/${inventoryItemId}/Counts/${countId}/Comments`),
        })
            .then((response) => {

                var mappedCountComments: InventoryItemCountComment[] = response.data.comments.map(ob => ({
                    id: ob.id,
                    createdBy: ob.createdBy,
                    createdDate: ob.createdDate,
                    comment: ob.comment
                }));

                return mappedCountComments;

            })
            .catch((error) => {
                throw error;
            });
    }
    


    moveItemCount(locationId: string, inventoryId: string, inventoryItemId: string, inventoryItemCountId: string, requestDto: InventoryItemCountMoveRequestDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/Inventories/${inventoryId}/Items/${inventoryItemId}/Counts/${inventoryItemCountId}/Move`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }
}
