import "./CreateInventoryItemPopup.css";

import { positiveDecimalNumber, regexIntegerOrDecimals, regexOnlyPositiveIntegers, validateRegex, validateRegexOrEmpty } from '../../../../../common/validators/ValidateRegexOrEmpty';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useClosePopup, usePopup } from '../../../../../lib/infrastructure/ui/UIServices';

import { AllValid } from '../../../../../common/validators/ValidateFormControls';
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormContainer } from '../../../../../lib/layouts/containers/form/FormContainer';
import { FormFieldTextArea } from '../../../../../lib/components/form/form-field/FormFieldTextArea';
import { FormFieldTextInput } from '../../../../../lib/components/form/form-field/FormFieldTextInput';
import { FormSection } from '../../../../../lib/components/form/section/FormSection';
import { FullScreenLoader } from '../../../../../lib/components/loader/FullScreenLoader';
import { InventoryItemCreateDTO } from "../../../models/dtos/InventoryItemDTOs/InventoryItemCreateDTO";
import { InventoryItemsService } from '../../../services/InventoryItemsService';
import { PopupActionButtons } from '../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons';
import { PopupContainer } from '../../../../../lib/layouts/containers/popup-container/PopupContainer';
import { PopupContent } from '../../../../../lib/layouts/containers/popup-content/PopupContent';
import { PopupHeader } from '../../../../../lib/layouts/containers/popup-header/PopupHeader';
import { Spacer } from '../../../../../lib/components/separator/Spacer';
import { translate } from '../../../../../lib/infrastructure/i18n/InternationalizationService';
import { trimString } from '../../../../../lib/utils/TrimString';
import { useFormControl } from '../../../../../lib/components/form/Form';
import { validateEmptyStrings } from '../../../../../lib/validators/ValidateEmptyStrings';
import { MaterialsService } from "../../../../../services/MaterialsService";
import { useServiceCallPro2 } from "../../../../../lib/hooks/useServiceCall";
import { MessageBar } from "../../../../../lib/components/message-bar/MessageBar";
import { ConditionalRender } from "../../../../../lib/functional/ConditionalRender";
import { AdminStorageLocationsService } from "../../../services/AdminStorageLocationsService";
import { validateBinFromStorageLocation } from "../../../../../common/validators/ValidateBinControl";

var inventoryItemsSvc = new InventoryItemsService();
var materialsSvc = new MaterialsService();
var storageSvc = new AdminStorageLocationsService();

function replaceCommaByPoint(value: string | undefined) {
    if (!value) return null;
    if (value?.includes(','))
        return trimString(value.replace(',', '.'));
    return trimString(value);
}

interface INewInventoryItemsPopupProps {
    locationID: string;
    inventoryID: string;
    inventoryName?: string;
    storage?: string;
    onCompletedOperations: () => void;
}

export function CreateInventoryItemPopup(props: INewInventoryItemsPopupProps) {


    const closePopup = useClosePopup();
    const openPopup = usePopup();

    const materialNumberRef = useRef<HTMLInputElement>(null);
    const storageLocationRef = useRef<HTMLInputElement>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showMaterialAlert, setShowMaterialAlert] = useState<boolean>(false);
    const [showStorageLocationAlert, setShowStorageLocationAlert] = useState<boolean>(false);

    const [storageBinCodes, setStorageBinCodes] = useState<string[]>();

    const sAPInventoryDocIdControl = useFormControl<string>({});

    const materialNumberControl = useFormControl<string>({
        validators: [validateEmptyStrings()],
        enableAutoValidate: true,
    });

    const materialDescriptionControl = useFormControl<string>({
        isDisabled: true,
        validators: [validateEmptyStrings()],
    });

    const batchControl = useFormControl<string>({});

    const storageControl = useFormControl<string>({
        validators: [validateEmptyStrings()],
        initialValue: props.storage,
        enableAutoValidate: true,
    });

    const unitsControl = useFormControl<string>({
        isDisabled: true,
        validators: [validateEmptyStrings()],
    });

    const binControl = useFormControl<string>({
        isDisabled: true,
        validators: [validateBinFromStorageLocation(storageBinCodes)],
        enableAutoValidate: true,
    });

    const quantitySAPControl = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexIntegerOrDecimals, true, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });

    const valueSapControl = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexIntegerOrDecimals, true, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });

    const specialId = useFormControl<string>({});

    const sapTypeId = useFormControl<string>({
        validators:[ validateRegex(regexOnlyPositiveIntegers, "COMMON.FORM.VALIDATIONS.PositiveNumber")],
        enableAutoValidate: true,
    });

    const commentsControl = useFormControl<string>({});





    const [getMaterialBySapCode, getMaterialBySapCodeIsLoading] = useServiceCallPro2(inventoryItemsSvc, materialsSvc.getBySapCode);
    const [getStorageLocationDetailsCall, getStorageLocationDetailsIsLoading] = useServiceCallPro2(storageSvc, storageSvc.getStorageLocationIncludeBins);
    const [postNewIem, postNewItemIsLoading] = useServiceCallPro2(inventoryItemsSvc, inventoryItemsSvc.addInventoryItem);





    const [materialSearchTimer, setMaterialSearchTimer] = useState<NodeJS.Timeout>();
    const [storageSearchTimer, setStorageSearchTimer] = useState<NodeJS.Timeout>();



    useEffect(() => {
        if (materialSearchTimer) {
            clearTimeout(materialSearchTimer);
        }
        var responseTimeOut = setTimeout(() => {
            if (materialSearchTimer) {

                if (!materialNumberControl.value) {
                    materialDescriptionControl.setValue("");
                    unitsControl.setValue("");
                    return;
                }


                getMaterialBySapCode("" + props.locationID, "" + materialNumberControl.value)
                    .then((resp) => {

                        if (resp.description && resp.units) {
                            materialDescriptionControl.setValue(resp.description);
                            unitsControl.setValue(resp.units);
                            unitsControl.setIsDisabled(true);
                            materialDescriptionControl.setIsDisabled(true);
                            setShowMaterialAlert(false);
                        }
                        else {
                            materialDescriptionControl.setValue("");
                            unitsControl.setValue("");
                            setShowMaterialAlert(true);
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        if (!error) return;
                        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>)
                    });
            }
        }, 500);
        setMaterialSearchTimer(responseTimeOut);
    }, [materialNumberControl.value]);







    useEffect(() => {

        if (storageSearchTimer)
            clearTimeout(storageSearchTimer);

        var responseTimeOut = setTimeout(() => {
            if (storageSearchTimer) {


                if (!storageControl.value) {
                    binControl.setValue("");
                    binControl.setIsDisabled(true);
                    setStorageBinCodes([]);
                    return;
                }

                getStorageLocationDetailsCall("" + props.locationID, "" + storageControl.value)
                    .then((resp) => {

                        if (!resp || !resp.name) {
                            setShowStorageLocationAlert(true);    
                            setStorageBinCodes([]);
                            binControl.setIsDisabled(true);
                            binControl.setValue("");

                        } else {
                            setShowStorageLocationAlert(false);
                            setStorageBinCodes(resp.binCodes);

                            if (resp.binCodes.length === 0) {
                                binControl.setValue("");
                                binControl.setIsDisabled(true);
                            }
                            else{
                                binControl.setIsDisabled(false);
                            }
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        if (!error) return;
                        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>)
                    });
            }

        }, 500);

        setStorageSearchTimer(responseTimeOut);

    }, [storageControl.value]);







    const handleCreateClicked = useCallback(() => {

        if (!props.locationID || !props.inventoryID) return;

        if (!AllValid(materialNumberControl.validate(), materialDescriptionControl.validate(), unitsControl.validate(), binControl.validate(), sapTypeId.validate(),
            storageControl.validate(), quantitySAPControl.validate())) return;

        

        var requestDto: InventoryItemCreateDTO = {
            sAPInventoryDocId: sAPInventoryDocIdControl.value ?? "",
            materialNumber: materialNumberControl.value ?? "",
            materialDescription: materialDescriptionControl.value ?? "",
            batch: batchControl.value ?? "",
            storage: storageControl.value ?? "",
            units: unitsControl.value ?? "",
            quantitySAP: quantitySAPControl.value ? parseFloat(replaceCommaByPoint("" + quantitySAPControl.value) || "0") : 0,
            bin: binControl.value ?? "",
            idTipoSAP:  sapTypeId.value && parseInt(sapTypeId.value, 10) ? parseInt(sapTypeId.value, 10) : 1,
            monetaryValueSAP:  valueSapControl.value ? parseFloat(replaceCommaByPoint("" + valueSapControl.value) || "0") : 0,
            specialSAP: specialId.value?? ""
        };
        

        postNewIem(props.locationID, props.inventoryID, requestDto)
            .then((_) => {
                setIsLoading(false);
                props.onCompletedOperations();
                closePopup();
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })
    }, [props.locationID, props.inventoryID, sAPInventoryDocIdControl.validate, materialNumberControl.validate,
    materialDescriptionControl.validate, batchControl.validate, storageControl.validate, unitsControl.validate,
    binControl.validate, specialId.value, valueSapControl.value, sapTypeId.value, sapTypeId.validate,
    quantitySAPControl.validate, sAPInventoryDocIdControl.value, materialNumberControl.value, materialDescriptionControl.value,
    batchControl.value, storageControl.value, unitsControl.value, quantitySAPControl.value, commentsControl.value, binControl.value,
        setIsLoading, props.onCompletedOperations, openPopup, closePopup, postNewIem]);




    return (
        <PopupContainer className="popup-add-inventory-item">
            {isLoading ? <FullScreenLoader /> : null}
            <PopupHeader
                title={translate("INVENTORY.ITEM.CreateInventoryItem")}
                subtitle={props.inventoryName ? translate("INVENTORY.Inventory") + ": " + props.inventoryName : ""}
            />
            {postNewItemIsLoading ? <FullScreenLoader /> : null}
            <PopupContent >
                <FormContainer>
                    <FormSection isInputGap>

                        <FormFieldTextInput
                            formControl={sAPInventoryDocIdControl}
                            label={translate("INVENTORY.ITEM.INFOANDFORM.SapInventoryDocId")}
                            placeholder={"Ex: 202"}
                        />

                        <FormFieldTextInput
                            formControl={materialNumberControl}
                            label={translate("INVENTORY.ITEM.INFOANDFORM.MaterialNumber")}
                            placeholder={"Ex: 100369"}
                            ref={materialNumberRef}
                            showLoading={getMaterialBySapCodeIsLoading}
                        />


                        <FormFieldTextInput
                            formControl={materialDescriptionControl}
                            label={translate("INVENTORY.ITEM.INFOANDFORM.MaterialDescription")}
                            placeholder={""}
                        />

                        <FormFieldTextInput
                            formControl={unitsControl}
                            label={translate("INVENTORY.ITEM.INFOANDFORM.Unit")}
                            placeholder={""}

                        />

                        <ConditionalRender if={showMaterialAlert}>
                            <div className="inexisting-material-alert">
                                <MessageBar type="error" text={translate("INVENTORY.ITEM.MESSAGES.InexistingMaterial")} />
                            </div>
                        </ConditionalRender>


                        <FormFieldTextInput
                            formControl={storageControl}
                            label={translate("INVENTORY.ITEM.INFOANDFORM.Storage")}
                            placeholder={"Ex: 5502"}
                            ref={storageLocationRef}
                            showLoading={getStorageLocationDetailsIsLoading}
                        />


                        <FormFieldTextInput
                            formControl={binControl}
                            label={translate("INVENTORY.ITEM.INFOANDFORM.Bin")}
                            placeholder={"Ex: WL102"}
                        />

                        <ConditionalRender if={showStorageLocationAlert}>
                            <div className="inexisting-storage-alert">
                                <MessageBar type="error" text={translate("INVENTORY.ITEM.MESSAGES.InexistingStorage")} />
                            </div>
                        </ConditionalRender>

                        <FormFieldTextInput
                            formControl={batchControl}
                            label={translate("INVENTORY.ITEM.INFOANDFORM.Batch")}
                            placeholder={"Ex: 2305010"}
                        />


                        <FormFieldTextInput
                            formControl={quantitySAPControl}
                            label={translate("INVENTORY.ITEM.INFOANDFORM.SAPQuantityExtended")}
                            placeholder={"Ex: 1000"}
                        />


                        <FormFieldTextInput
                            formControl={valueSapControl}
                            label={translate("INVENTORY.ITEM.INFOANDFORM.SapValue")}
                            placeholder={"Ex: 32,43"}
                        />

                        <FormFieldTextInput
                            formControl={sapTypeId}
                            label={translate("INVENTORY.ITEM.INFOANDFORM.SapTypeId")}
                            placeholder={"Ex: 1"}
                        />

                        <FormFieldTextInput
                            formControl={specialId}
                            label={"Special"}
                            placeholder={"Ex: E"}
                        />

                    </FormSection>

                    <FormFieldTextArea
                        formControl={commentsControl}
                        label={translate("INVENTORY.ITEM.INFOANDFORM.Comments")}
                        placeholder={translate("INVENTORY.ITEM.INFOANDFORM.CommentsPlaceholder")}
                    />
                </FormContainer>
                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Create"),
                            type: "primary",
                            onClick: handleCreateClicked
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}