import "./InventoryStorageDetailsPage.css";

import * as QueryMapper from "../item-pages/InventoryItemQueryParamHelper";

import { GetInvItemStatus, InventoryItemStatusAsEnum, InventoryItemStatusEnum, InventoryItemStatusTranslationTerms } from "../../models/domain/inventory-items/InventoryItemStatusEnum";
import { IInventoryStorageLocationStatsIntrinsicBehavior, InventoryStorageLocationStatsSection } from "../../components/InventoryStorageLocationStatsSection";
import { formatNonIntegerNumber, formatRoundNumber, } from "../../../../common/helpers/RoundNumber";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { ReactComponent as AddIcon } from "../../../../lib/assets/icons/add.svg";
import { ReactComponent as ArrowDown } from "../../../../lib/assets/icons/arrowDown.svg";
import { ReactComponent as ArrowUp } from "../../../../lib/assets/icons/arrowUp.svg";
import { BetweenNumbersHelper } from "../../../../lib/helpers/BetweenNumbersHelper";
import { Button } from "../../../../lib/components/buttons/Button";
import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { Checkbox } from "../../../../lib/components/checkbox/Checkbox";
import { ReactComponent as CleanIcon } from "../../../../lib/assets/icons/clean.svg";
import { ReactComponent as CloseIcon } from "../../../../lib/assets/icons/lock-btn.svg";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { ConditionalRender } from "../../../../lib/functional/ConditionalRender";
import { ConsultHistoryLinesPopup } from "../item-pages/popups/ConsultHistoryLinesPopup";
import { ContextualMenu } from "../../../../lib/components/contextual-menu/ContextualMenu";
import { CreateInventoryItemPopup } from "../item-pages/popups/CreateInventoryItemPopup";
import { ReactComponent as EditIcon } from "../../../../lib/assets/icons/editar.svg";
import { EditInventoryDocumentItemIDPopup } from "../item-pages/popups/EditInventoryDocumentItemIDPopup";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormFieldSelectMultipleSimple } from "../../../../lib/components/form/form-field/FormFieldSelectMultipleSimple";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { ReactComponent as HistoryIcon } from "../../../../lib/assets/icons/history.svg";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";
import { InventoryItemState } from "../../models/dtos/InventoryItemDTOs/InventoryItemState";
import { InventoryItemSummary } from "../../models/domain/inventory-items/InventoryItemSummary";
import { InventoryItemsService } from "../../services/InventoryItemsService";
import { InventoryStorageLocationDetails } from "../../models/domain/storage/InventoryStorageLocationDetails";
import { ItemStateOption } from "../../models/domain/inventory-items/ItemState";
import { ReactComponent as MoreActionsIcon } from "../../../../lib/assets/icons/more-actions.svg";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../../models/api/gate/Permission";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { RoundIconButton } from "../../../../lib/components/buttons/RoundIconButton";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { ReactComponent as SearchIcon } from "../../../../lib/assets/icons/search-icon.svg";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { StorageLocationItemsQueryOptions } from "../../models/dtos/InventoryStorageLocationDTOs/StorageLocationItemsQueryOptions";
import { StorageService } from "../../services/InventoryStorageLocationsService";
import { Tag } from "../../../../lib/components/tag/Tag";
import { Tooltip } from "../../../../lib/components/tooltip/Tooltip";
import { ReactComponent as ValidateIcon } from "../../../../lib/assets/icons/check-btn.svg";
import { ValidateInvDocItemCountPopup } from "../item-pages/popups/ValidateInvDocItemCountPopup";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { getInventoryItemStatusCss } from "../../../../common/helpers/GetInventoryItemStatusCss";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useInventoryStatus } from "../../../../lib/infrastructure/inventory-status/useInventoryStatus";
import { useServiceCallPro2 } from "../../../../lib/hooks/useServiceCall";
import { useUpdatePageData } from "../../../../lib/infrastructure/navigation/hooks/useUpdatePageData";

const svc = new StorageService();
const inventoryItemsSvc = new InventoryItemsService();

const labelInventoryItemStateSelector = (item: ItemStateOption) => item.description;
const idInventoryItemStateSelector = (item: ItemStateOption) => item.id;


export const topPermissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCAL_ADMIN,
    Permission.FINANCES
];


export function InventoryStorageDetailsPage() {

    const inventoryItemsStatesOptions = useMemo(() => {
        return GetInvItemStatus();
    }, [GetInvItemStatus]);

    const statsSectionRef = useRef<IInventoryStorageLocationStatsIntrinsicBehavior>(null);
    const { isLoadingPermissions, hasPermission } = useHasPermissions(topPermissions);

    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const updateRouteData = useUpdatePageData();
    const createNotification = useCreateNotification();
    const windowResize = useWindowResize();

    const inventoryStatus = useInventoryStatus();

    const upperRowRef = useRef<HTMLDivElement>(null);
    const bottomRowRef = useRef<HTMLDivElement>(null);

    const { id: inventoryID, storageCode } = useParams();
    const locationID = useGlobalLocation();

    const [inventoryItems, setInventoryItems] = useState<InventoryItemSummary[]>();
    const [totalItems, setTotalItems] = useState<number>(0);
    const [storageLocationDetails, setStorageLocationDetails] = useState<InventoryStorageLocationDetails>();
    const [isFiltersPanelExpanded, setIsFiltersPanelExpanded] = useState<boolean>(false);
    const [isFiltersWrapped, setIsFiltersWrapped] = useState<boolean>(false);

    const [timer, setTimer] = useState<NodeJS.Timeout>();


    /****************************
    * Service Calls * 
    *****************************/
    const [getStorageLocationItems, storageLocationItemsIsLoading] = useServiceCallPro2(svc, svc.getStorageLocationItemsByCode);
    const [validateItem, validateItemIsLoading] = useServiceCallPro2(inventoryItemsSvc, inventoryItemsSvc.updateInventoryItemState);
    const [closeItem, closeItemIsLoading] = useServiceCallPro2(inventoryItemsSvc, inventoryItemsSvc.closeInventoryItem);
    const [getStorageDetails, getStorageDetailsIsLoading] = useServiceCallPro2(svc, svc.getStorageLocationDetails);
    const [autoValidateItems, autoValidateItemsIsLoading] = useServiceCallPro2(svc, svc.autoValidateStorageItems);



    /****************************
          FILTER STATES
    *****************************/

    const [itemsPerPage, setItemsPerPage] = useState<number>(parseInt(searchParams.get("pageSize") || "10"));
    const [currentPage, setCurrentPage] = useState<number>(parseInt(searchParams.get("page") || "0"));
    const materialNumberFilter = useFormControl<string>({ initialValue: searchParams.get("materialNumber") ?? undefined });
    const materialDescriptionFilter = useFormControl<string>({ initialValue: searchParams.get("materialDescription") ?? undefined });
    const batchFilter = useFormControl<string>({ initialValue: searchParams.get("batch") ?? undefined });
    const binFilter = useFormControl<string>({ initialValue: searchParams.get("bin") ?? undefined });
    const inventoryItemStatesFilter = useFormControl<ItemStateOption[]>({ initialValue: QueryMapper.mapToItemStateOption(searchParams.getAll("idItemStatus")) });
    const [isNewItemsSelected, setIsNewItemsSelected] = useState<boolean>(searchParams.get("newItemsSelected") !== null ? true : false);



    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/


    useEffect(() => {
        getStorageLocation();
    }, [inventoryID, storageCode]);



    useEffect(() => {
        const controller = new AbortController();
        getStorageItems(controller);
        return () => { controller.abort() };
    }, [itemsPerPage, currentPage]);




    useEffect(() => {

        if (timer)
            clearTimeout(timer);

        var responseTimeOut = setTimeout(() => {

            if (timer && currentPage === 0) {
                const controller = new AbortController();
                getStorageItems(controller);
                return () => { controller.abort() };
            }
            else if (timer)
                setCurrentPage(0);

        }, 750);

        setTimer(responseTimeOut);
    }, [materialDescriptionFilter.value, materialNumberFilter.value, batchFilter.value, binFilter.value, inventoryItemStatesFilter.value, isNewItemsSelected]);






    /****************************
       * DATA REQUESTS * 
     *****************************/

    const getStorageItems = useCallback((controller: AbortController | undefined) => {

        if (!locationID || !inventoryID || !storageCode) return;

        var request: StorageLocationItemsQueryOptions = {
            page: currentPage,
            pageLength: itemsPerPage,
            materialNumber: materialNumberFilter.value,
            materialDescription: materialDescriptionFilter.value,
            batch: batchFilter.value,
            bin: binFilter.value,
            newItems: isNewItemsSelected ? isNewItemsSelected : undefined,
            state: inventoryItemStatesFilter.value?.map((obj) => InventoryItemStatusAsEnum[parseInt(obj.id)])
        };

        const url = new URL(window.location.origin + window.location.pathname);
        request.page && url.searchParams.append('page', `${request.page}`);
        request.pageLength && url.searchParams.append('pageSize', `${request.pageLength}`);
        request.materialNumber && url.searchParams.append('materialNumber', `${request.materialNumber}`);
        request.materialDescription && url.searchParams.append('materialDescription', `${request.materialDescription}`);
        request.batch && url.searchParams.append('batch', `${request.batch}`);
        request.bin && url.searchParams.append('bin', `${request.bin}`);
        request.newItems && url.searchParams.append('newItemsSelected', `${request.newItems}`);
        request.state?.forEach(status => {
            request.state && url.searchParams.append('idItemStatus', `${status}`);
        });

        window.history.pushState(null, '', url.toString());


        getStorageLocationItems(`${locationID}`, inventoryID, storageCode, controller?.signal ? controller.signal : undefined, request)
            .then((data) => {
                if (data) {
                    setInventoryItems(data.items);
                    setTotalItems(data.totalItems);
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, inventoryID, storageCode, setInventoryItems, getStorageLocationItems,
        currentPage, itemsPerPage, materialNumberFilter.value, materialDescriptionFilter.value, batchFilter.value, binFilter.value, isNewItemsSelected, inventoryItemStatesFilter.value]);


    const getStorageLocation = useCallback(() => {

        if (!locationID || !inventoryID || !storageCode) return;

        getStorageDetails(`${locationID}`, inventoryID, storageCode)
            .then((data) => {
                updateRouteData(["##STORAGE##", data.name ? storageCode + " - " + data.name : storageCode]);
                setStorageLocationDetails(data);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, inventoryID, storageCode, updateRouteData, getStorageDetails, setStorageLocationDetails,
        currentPage, itemsPerPage, materialDescriptionFilter.value, materialDescriptionFilter.value,
        batchFilter.value, binFilter.value, inventoryItemStatesFilter.value, isNewItemsSelected]);


    /****************************
     * USER ACTIONS
     *****************************/

    const onPageAndItemsChanged = useCallback((pageLength: number, currentPage: number) => {
        setCurrentPage(currentPage);
        setItemsPerPage(pageLength)
    }, [setCurrentPage, setItemsPerPage]);


    const handleClearFiltersButtonClicked = useCallback(() => {
        setCurrentPage(0);
        materialNumberFilter.setValue("");
        materialDescriptionFilter.setValue("");
        batchFilter.setValue("");
        binFilter.setValue("");
        setIsNewItemsSelected(false);
        inventoryItemStatesFilter.setValue([]);
    }, [binFilter.setValue, materialDescriptionFilter.setValue, materialNumberFilter.setValue, batchFilter.setValue, setIsNewItemsSelected, setCurrentPage]);


    const handleOnValidateItemClicked = useCallback((itemId: string) => {
        if (!locationID || !inventoryID || !itemId) return;

        validateItem(`${locationID}`, inventoryID, itemId, { state: InventoryItemState.VALIDATED }).then(_ => {

            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate(
                        "INVENTORY.ITEM.MESSAGES.ValidateItemSuccessMessage"
                    )}
                />
            );
            getStorageItems(undefined);
            statsSectionRef?.current?.refresh();
        }).catch((error) => {
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }, [locationID, inventoryID, validateItem, getStorageItems, openPopup, createNotification, statsSectionRef]);


    const handleOnCloseItemClicked = useCallback((itemId: string) => {
        if (!locationID || !inventoryID || !itemId) return;

        closeItem(`${locationID}`, inventoryID, itemId).then(_ => {
            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("INVENTORY.ITEM.MESSAGES.CloseItemSuccessMessage")}
                />
            );
            getStorageItems(undefined);
            statsSectionRef?.current?.refresh();
        }).catch((error) => {
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }, [locationID, inventoryID, getStorageItems, openPopup, createNotification, statsSectionRef, closeItem]);



    const autoValidateStorageItems = useCallback(() => {

        if (!locationID || !inventoryID || !storageCode) return;

        autoValidateItems(`${locationID}`, inventoryID, storageCode)
            .then((_) => {
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("INVENTORY.ITEM.MESSAGES.AutoValidateItemsSuccessMessage")}
                    />
                );
                getStorageItems(undefined);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, inventoryID, storageCode, getStorageItems, createNotification, openPopup, autoValidateItems]);




    const handleAutoValidateBtnClicked = useCallback(() => {
        openPopup(
            <WarningPopup
                onDismissClicked={() => { closePopup(); }}
                onConfirmClicked={() => autoValidateStorageItems()}
                dismissText={translate("COMMON.Cancel")}
                confirmText={translate("INVENTORY.STORAGE.AutoValidate")}
            >
                {translate("INVENTORY.ITEM.MESSAGES.AutoValidateItemsConfirmText")}
            </WarningPopup>
        )

    }, [closePopup, autoValidateStorageItems, openPopup]);




    const handleClickCreateItem = useCallback(() => {
        if (inventoryStatus.isLoadingInventoryStatus || inventoryStatus.isInventoryClosed || !inventoryID) return null;

        openPopup(
            <CreateInventoryItemPopup
                onCompletedOperations={() => { getStorageItems(undefined); statsSectionRef?.current?.refresh(); }}
                locationID={`${locationID}`}
                inventoryID={inventoryID}
                storage={storageCode}
            />)

    }, [inventoryID, openPopup, locationID, storageCode, inventoryStatus.isLoadingInventoryStatus, inventoryStatus.isInventoryClosed])










    /****************************
     * CSS & HTML
     *****************************/

    const renderInvItemActionBtn = useCallback((status: InventoryItemStatusEnum, item: InventoryItemSummary) => {

        if (inventoryStatus.isLoadingInventoryStatus || inventoryStatus.isInventoryClosed) return null;

        switch (status) {
            case InventoryItemStatusEnum.READY_TO_BE_VALIDATED:
                if (!item.permissions?.canValidate) return;
                return <Tooltip
                    text={translate("INVENTORY.ITEM.ValidateCounts")}>
                    <RoundIconButton icon={<ValidateIcon />}
                        onClick={() => {
                            if (!item.id || !locationID || !inventoryID) return;
                            openPopup(
                                <WarningPopup
                                    onDismissClicked={() => { closePopup(); }}
                                    onConfirmClicked={() => handleOnValidateItemClicked("" + item.id)}
                                    dismissText={translate("COMMON.Cancel")}
                                    confirmText={translate("INVENTORY.ITEM.Validate")}
                                >
                                    {translate("INVENTORY.ITEM.MESSAGES.ValidateItemConfirmText")}
                                </WarningPopup>
                            )
                        }} />
                </Tooltip >;
            case InventoryItemStatusEnum.COUNTING:
                if (!item.permissions?.canValidate) return;
                return <Tooltip
                    text={translate("INVENTORY.ITEM.ValidateCounts")}>
                    <RoundIconButton icon={<ValidateIcon />}
                        onClick={() => {
                            if (!item.id || !locationID || !inventoryID) return;
                            if (item.isProblematic || item.quantityCounted === 0)
                                openPopup(<ValidateInvDocItemCountPopup
                                    locationID={"" + locationID}
                                    inventoryID={"" + inventoryID}
                                    inventoryItemID={"" + item.id}
                                    hasZeroQuantity={item.quantityCounted === 0}
                                    onCompletedOperations={() => {
                                        statsSectionRef?.current?.refresh();
                                        getStorageItems(undefined);
                                    }} />)
                            else
                                openPopup(
                                    <WarningPopup
                                        onDismissClicked={() => { closePopup(); }}
                                        onConfirmClicked={() => handleOnValidateItemClicked("" + item.id)}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate("INVENTORY.ITEM.Validate")}
                                    >
                                        {translate("INVENTORY.ITEM.MESSAGES.ValidateItemConfirmText")}
                                    </WarningPopup>
                                )
                        }} />
                </Tooltip >;
            case InventoryItemStatusEnum.VALIDATED:
                if (!item.permissions?.canClose) return;
                return <Tooltip text={translate("INVENTORY.ITEM.CloseItem")}>
                    <RoundIconButton icon={<CloseIcon />}
                        onClick={() =>
                            openPopup(
                                <WarningPopup
                                    onDismissClicked={() => { closePopup(); }}
                                    onConfirmClicked={() => handleOnCloseItemClicked("" + item.id)}
                                    dismissText={translate("COMMON.Cancel")}
                                    confirmText={translate("INVENTORY.ITEM.Close")}
                                >
                                    {translate("INVENTORY.ITEM.MESSAGES.CloseItemConfirmText")}
                                </WarningPopup>
                            )} /> </Tooltip>;
            default:
                return null;
        }
    }, [handleOnValidateItemClicked, handleOnCloseItemClicked, openPopup, closePopup, getStorageItems, inventoryID, locationID,
        inventoryStatus.isLoadingInventoryStatus, inventoryStatus.isInventoryClosed, statsSectionRef]);



    const columns: ColumnDefinition<InventoryItemSummary>[] = useMemo(() => [
        {
            cellRenderProp: (v) => windowResize > 1200 ? v.materialNumber : <div className="info-box">
                <div className="info">{translate("INVENTORY.ITEM.INFOANDFORM.Material") + ": "}<span>{v.materialNumber}</span></div>
                <div className="info">{translate("INVENTORY.ITEM.INFOANDFORM.Description") + ": "}<span>{v.materialDescription}</span></div>
                <div className="info">{translate("INVENTORY.ITEM.INFOANDFORM.Batch") + ": "}<span>{v.batch}</span></div>
                <div className="info">{translate("INVENTORY.ITEM.INFOANDFORM.Bin") + ": "}<span>{v.bin}</span></div>
            </div>,
            headerRender: "Info",
            width: "40%",
            isVisible: BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1200),
        },
        {
            cellRenderProp: (v) => v.materialNumber,
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.Material"),
            width: "15%",
            isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1200),
            isMobilePrimaryCell: true
        },
        {
            cellRenderProp: (v: InventoryItemSummary) => v.materialDescription,
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.Description"),
            isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1200),
            width: "18%"
        },
        {
            cellRenderProp: (v: InventoryItemSummary) => v.batch,
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.Batch"),
            isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1200),
        },

        {
            cellRenderProp: (v: InventoryItemSummary) => v.bin,
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.Bin"),
            width: "8%",
            isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1200),
        },
        {
            cellRenderProp: (v: InventoryItemSummary) =>
                <div className="special-attributes">
                    <div className="special-sap">
                        <div className="label">Special:</div>
                        <div className="value"> {v.specialSAP ? v.specialSAP : "-"} </div>
                    </div>
                    <div className="sap-type">
                        <div className="label"> {translate("INVENTORY.ITEM.INFOANDFORM.SapTypeId") + ":"} </div>
                        <div className="value"> {v.typeSAP ? v.typeSAP : "-"} </div>
                    </div>
                </div>,
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.Attributes"),
            width: "10%"
        },

        {
            cellRenderProp: (v: InventoryItemSummary) => v.units,
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.Unit"),
        },
        {
            cellRenderProp: (v: InventoryItemSummary) => v.quantitySAP === 0 ? "0" : formatNonIntegerNumber(v.quantitySAP, 3),
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.SAPQuantity"),
            width: "8%"
        },
        {
            cellRenderProp: (v: InventoryItemSummary) => v.quantityCounted === 0 ? "0" : formatNonIntegerNumber(v.quantityCounted, 3),
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.CountedQuantity"),
            width: "10%"
        },
        {
            cellRenderProp: (v) => v.quantityDeviationPercentage || v.monetaryDeviationValue ?
                <div className="deviation">
                    <div className="qty-difference-block">
                        <div className="label">{translate("INVENTORY.DEVIATION.INFOANDFORM.Quantity") + ":"}</div>
                        <div className={v.isProblematic ? "value-red" : undefined}>
                            {formatNonIntegerNumber(v.quantityDeviationPercentage, 3) + "%"}
                        </div>
                    </div>

                    <div className="monetary-block">
                        <div className="label">{translate("INVENTORY.DEVIATION.INFOANDFORM.Monetary") + ":"}</div>
                        <div className={v.isProblematic ? "value-red" : undefined}>
                            {formatRoundNumber(v.monetaryDeviationValue, 2) + "€"}
                        </div>
                    </div>
                </div> : null,
            headerRender: translate("INVENTORY.ITEM.INFOANDFORM.Deviation"),
            width: "10%",
            isMobilePrimaryCell: true
        },
        {
            cellRenderProp: (v: InventoryItemSummary) => <Tag
                text={translate(InventoryItemStatusTranslationTerms[v.status])}
                backgroundColor={getInventoryItemStatusCss(v.status)}
                isTiny={windowResize < 1024}
            />,
            headerRender: translate("INVENTORY.Status"),
            width: "8%",
            isStatusCell: true
        },
        {
            cellRenderProp: (v: InventoryItemSummary) => renderInvItemActionBtn(v.status, v),
            width: "24px",
            isMobileHeaderIcon: true
        },
        {
            cellRenderProp: (v) => {
                if (inventoryStatus.isLoadingInventoryStatus || inventoryStatus.isInventoryClosed || !v.isNew) return null;
                if (isLoadingPermissions || !hasPermission) return null;
                return (<ContextualMenu
                    items={[
                        {
                            label: translate("INVENTORY.POPUP.EditDocumentSapId"),
                            icon: <EditIcon />,
                            navigateTo: "",

                            onClick: () => {
                                if (!v.id || !locationID || !inventoryID) return;
                                openPopup(
                                    <EditInventoryDocumentItemIDPopup
                                        locationID={"" + locationID}
                                        inventoryID={"" + inventoryID}
                                        inventoryItemID={"" + v.id}
                                        onCompletedOperations={() => getStorageItems(undefined)}
                                    />
                                );
                            }
                        },
                        // {
                        //     label: translate("INVENTORY.POPUP.History"),
                        //     navigateTo: "",
                        //     icon: <HistoryIcon />, onClick: () => {
                        //         openPopup(
                        //             <ConsultHistoryLinesPopup
                        //                 locationID={"" + locationID}
                        //                 inventoryID={"" + inventoryID}
                        //                 inventoryItemID={"" + v.id}
                        //                 onCompletedOperations={() => getStorageItems(undefined)}
                        //             />
                        //         );
                        //     }
                        // }
                    ]}
                >
                    <ScalableIconButton size={24} icon={<MoreActionsIcon />} />
                </ContextualMenu>)
            },
            columnKey: "moreActions",
            width: "24px",
            isMobileHeaderIcon: true
        },
    ], [inventoryItems, getStorageItems, locationID, inventoryID, windowResize,
        inventoryStatus.isLoadingInventoryStatus, inventoryStatus.isInventoryClosed,
        isLoadingPermissions, hasPermission]);


    useEffect(() => {
        const upperRowNode = upperRowRef.current;
        const bottomRowNode = bottomRowRef.current;

        if (upperRowNode && bottomRowNode) {
            let hasWrapOccurred = Array.from(upperRowNode.children, child => child as HTMLElement).some((child, i, arr) => {
                return i > 0 && child.offsetTop > arr[i - 1].offsetTop;
            });

            while (hasWrapOccurred) {
                const lastChild = upperRowNode.lastElementChild;
                setIsFiltersWrapped(true);
                if (lastChild) {
                    upperRowNode.removeChild(lastChild);
                    bottomRowNode.prepend(lastChild);
                }
                hasWrapOccurred = Array.from(upperRowNode.children, child => child as HTMLElement).some((child, i, arr) => {
                    return i > 0 && child.offsetTop > arr[i - 1].offsetTop;
                });
            }
        }
    }, [windowResize]);


    const renderFilters = useMemo(() => {
        return <div className="filters">
            <div className="upper-row-filters" ref={upperRowRef}>
                <FormFieldTextInput
                    formControl={materialNumberFilter}
                    placeholder={translate("INVENTORY.Material")}
                    icon={<SearchIcon />}
                    className="search-box"
                />
                <FormFieldTextInput
                    formControl={materialDescriptionFilter}
                    placeholder={translate("INVENTORY.Description")}
                    icon={<SearchIcon />}
                    className="search-box"
                />
                <FormFieldTextInput
                    formControl={batchFilter}
                    placeholder={translate("INVENTORY.Batch")}
                    icon={<SearchIcon />}
                    className="search-box"
                />
                <FormFieldTextInput
                    formControl={binFilter}
                    placeholder={translate("INVENTORY.ITEM.INFOANDFORM.Bin")}
                    icon={<SearchIcon />}
                    className="search-box"
                />
                <FormFieldSelectMultipleSimple<ItemStateOption>
                    placeholder={translate("COMMON.FORM.SelectStatusPlaceholder")}
                    options={inventoryItemsStatesOptions.filter((a) => a.id !== "0")}
                    labelSelector={labelInventoryItemStateSelector}
                    idSelector={idInventoryItemStateSelector}
                    formControl={inventoryItemStatesFilter}
                    maxHeightOptions={"16rem"}
                />
                <Checkbox
                    text={translate("INVENTORY.ITEM.NewItems")}
                    isChecked={isNewItemsSelected}
                    onClick={() => setIsNewItemsSelected(!isNewItemsSelected)}
                />
            </div>
            <div className="action-button-filters">
                {isFiltersWrapped ? <IconButton
                    icon={isFiltersPanelExpanded ? <ArrowUp /> : <ArrowDown />}
                    type="tertiary"
                    onClick={() => setIsFiltersPanelExpanded(!isFiltersPanelExpanded)}
                    className="view-more-filters-btn"
                /> : null}
                <Tooltip text={translate("COMMON.FORM.CleanFilters")}>
                    <IconButton
                        icon={<CleanIcon />}
                        type="secondary"
                        onClick={handleClearFiltersButtonClicked}
                        className="reset-filters-btn"
                    />
                </Tooltip>
            </div>
            <div className={"bottom-row-filters" + (isFiltersPanelExpanded ? " expanded" : "")} ref={bottomRowRef}>
            </div>
        </div>
    }, [materialNumberFilter, isNewItemsSelected, setIsNewItemsSelected, handleClearFiltersButtonClicked, batchFilter, materialDescriptionFilter, binFilter, isFiltersPanelExpanded, isFiltersWrapped]);




    const renderActionButtons = useMemo(() => {

        if (inventoryStatus.isLoadingInventoryStatus || inventoryStatus.isInventoryClosed) return;
        return windowResize > 768 ?
            <>
                <ConditionalRender if={!getStorageDetailsIsLoading && (storageLocationDetails?.isCurrentUserStorageManager ?? false)} >
                    <Button
                        text={translate("INVENTORY.STORAGE.AutoValidate")}
                        type="status-blue"
                        onClick={handleAutoValidateBtnClicked}
                    /></ConditionalRender>
                <ConditionalRender if={!isLoadingPermissions && hasPermission} >
                    <Button
                        text={translate("INVENTORY.STORAGE.CreateItem")}
                        type="primary"
                        onClick={handleClickCreateItem}
                    /> </ConditionalRender>
            </> :
            <ConditionalRender if={!isLoadingPermissions && hasPermission} >
                <IconButton
                    icon={<AddIcon />}
                    type="primary"
                    onClick={handleClickCreateItem}
                    isDisabled={isLoadingPermissions || !hasPermission}
                />
            </ConditionalRender>
    }, [windowResize, handleAutoValidateBtnClicked, handleClickCreateItem, inventoryStatus.isLoadingInventoryStatus, getStorageDetailsIsLoading,
        isLoadingPermissions, hasPermission, inventoryStatus.isInventoryClosed, storageLocationDetails?.isCurrentUserStorageManager]);


    const renderStatsCards = useMemo(() => {
        if (windowResize > 1200)
            return <div className="status-cards"><InventoryStorageLocationStatsSection ref={statsSectionRef} locationId={`${locationID}`} inventoryId={inventoryID} storageLocationCode={storageCode} /></div>;
    }, [windowResize, inventoryID, locationID, storageCode])


    return (
        <PageLayout
            tabTitle={translate("INVENTORY.Storage")}
            pageTitle={translate("INVENTORY.Storage")}
            className="storage-details"
            showBreadcrumb
            actions={renderActionButtons}
        >
            {validateItemIsLoading || closeItemIsLoading || autoValidateItemsIsLoading ? <FullScreenLoader /> : null}
            <div className="storage-general-info">
                <CardContainer className="storage-data">
                    <div className="storage-details-header">
                        <div className="title">{translate("INVENTORY.StorageData")}</div>
                    </div>
                    <Spacer mode="vertical" px="16" />
                    <FormSection unsetFlexGrow>
                        <InfoBlock
                            label={translate("INVENTORY.STORAGE.INFOANDFORM.SAPLoc")}
                            value={storageCode || ""}
                        />
                        <InfoBlock
                            label={translate("GLOBALADMIN.STORAGES.Info.Name")}
                            value={storageLocationDetails?.name || "-"}
                        />
                    </FormSection>
                </CardContainer>
                {renderStatsCards}
            </div>

            <Spacer mode="vertical" px="20" />
            <div className="subtitle">{translate("INVENTORY.STORAGE.InventoryItems")}</div>
            <Spacer mode="vertical" px="12" />
            {renderFilters}
            <Spacer mode="vertical" px="12" />


            <ResponsiveDataTable
                className="items-table"
                columnDefinitions={columns}
                items={inventoryItems || []}
                totalitems={totalItems}
                paginationOptions={{ itemsPerPage: itemsPerPage || 0, itemsPerPageOptions: [10, 20, 30, 40, 50] }}
                currentpage={currentPage}
                isLoading={storageLocationItemsIsLoading}
                onClickRow={(item) => navigate(`items/${item.id}`)}
                onPageAndItemsChanged={onPageAndItemsChanged}
            />
        </PageLayout >
    );
}
