import "./AddInventoryItemCountCommentPopup.css";

import { CommentInfo, ItemComment } from "../components/ItemComment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";
import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextArea } from "../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { InventoryItemCountsService } from "../../../services/InventoryItemCountsService";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useServiceCallPro2 } from "../../../../../lib/hooks/useServiceCall";
import { validateNullStrings } from "../../../../../lib/validators/ValidateEmptyStringsN";
import { DateTime } from "luxon";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { Notification } from "../../../../../lib/components/notifications/Notification";


var svc = new InventoryItemCountsService();

export interface AddInventoryItemCountCommentPopupProps {
    locationID: string;
    inventoryID: string;
    inventoryItemID: string;
    inventoryItemCountID: string;
    onCompletedOperations?: () => void;
    isItemClosed: boolean;
}

export function AddInventoryItemCountCommentPopup(props: AddInventoryItemCountCommentPopupProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();

    const [getCountCommentsCall, getCountCommentsCallIsLoading] = useServiceCallPro2(svc, svc.getInventoryItemCountComments);
    const [addCommentCountCall, addCommentCountCallIsLoading] = useServiceCallPro2(svc, svc.addInventoryItemCountComment);

    const [comments, setComments] = useState<CommentInfo[]>();

    const commentFormControl = useFormControl<string>({
        validators: [validateNullStrings()],
        enableAutoValidate: true,
    });



    useEffect(() => {

        getCountCommentsCall(props.locationID, props.inventoryID, props.inventoryItemID, props.inventoryItemCountID)
            .then((r) => {

                var mappedComments: CommentInfo[] = r.map((c) => ({
                    text: c.comment,
                    createdBy: c.createdBy,
                    createdDate: c.createdDate ? DateTime.fromISO(c.createdDate).toFormat("dd/MM/yyyy HH'h'mm") : "",
                }));

                setComments(mappedComments);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })
    }, []);





    const handleAddCommentBtnClicked = useCallback(() => {

        if (!commentFormControl.validate()) return;

        if (!props.locationID || !props.inventoryID || !props.inventoryItemID || !props.inventoryItemCountID) return;

        addCommentCountCall(props.locationID, props.inventoryID, props.inventoryItemID, props.inventoryItemCountID, { comment: commentFormControl.value ?? null })
            .then((_) => {
                props.onCompletedOperations && props.onCompletedOperations();
                closePopup();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("INVENTORY.ITEM.MESSAGES.AddCommentCountSuccessMessage")}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [props.locationID, props.inventoryID, props.inventoryItemID, props.inventoryItemCountID, props.onCompletedOperations,
    commentFormControl.value, commentFormControl.validate, addCommentCountCall, closePopup, openPopup, createNotification]);





    const renderComments = useMemo(() => {
        if (comments && comments?.length > 0)
            return <>
                <div className="comments">
                    {comments?.map((comment, idx) => <ItemComment key={idx} comment={comment} />)}
                </div>
                <Spacer mode="vertical" px={20} />
            </>
    }, [comments])




    return (
        <PopupContainer className="popup-add-item-count-comment">
            {addCommentCountCallIsLoading ? <FullScreenLoader /> : null}
            <PopupHeader
                title={!props.isItemClosed ? translate("INVENTORY.ITEM.AddComment") : translate("INVENTORY.ITEM.INFOANDFORM.SeeComments")}
            />
            <PopupContent isLoading={getCountCommentsCallIsLoading}>
                {renderComments}
                {!props.isItemClosed ?
                    <>
                        <FormContainer>
                            <FormFieldTextArea
                                formControl={commentFormControl}
                                label={translate("INVENTORY.ITEM.INFOANDFORM.SingularComment")}
                                placeholder={translate("INVENTORY.ITEM.INFOANDFORM.SingularCommentPlaceholder")}
                            />
                        </FormContainer>
                        <Spacer mode={"vertical"} px="30"></Spacer>
                        <PopupActionButtons
                            buttons={[
                                {
                                    text: translate("COMMON.Cancel"),
                                    type: "tertiary",
                                    onClick: () => closePopup(),
                                },
                                {
                                    text: translate("COMMON.Add"),
                                    type: "primary",
                                    onClick: handleAddCommentBtnClicked
                                },
                            ]}
                        />
                        </>
                : null}
            </PopupContent>
        </PopupContainer>
    );
}